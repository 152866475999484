<template>
    <v-spa-layout-page>
        <template v-slot:title>
            {{ $t('pages.exports.index.title') }}
        </template>
        <template v-slot:content>

            <div>
                <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
    
                <div v-else>
                    <table v-if="collection && collection.isNotEmpty()" :class="`${$spa.css.table.table} border border-gray-200 rounded`">
                        <thead>
                            <tr>
                                <th class="px-3 py-2 text-sm rounded-tl bg-slate-100">
                                    {{ $t('export.labels.id') }}
                                </th>
                                <th class="px-3 py-2 text-sm bg-slate-100">
                                    {{ $t('export.labels.date') }}
                                </th>
                                <th class="px-3 py-2 text-sm bg-slate-100">
                                    {{ $t('export.labels.period') }}
                                </th>
                                <th class="px-3 py-2 text-sm bg-slate-100">
                                    {{ $t('export.labels.client') }}
                                </th>
                                <th class="px-3 py-2 text-sm bg-slate-100">
                                    {{ $t('export.labels.type') }}
                                </th>
                                <th class="px-3 py-2 text-sm bg-slate-100">
                                    {{ $t('export.labels.format') }}
                                </th>
                                <th class="px-3 py-2 text-sm bg-slate-100">
                                    {{ $t('export.labels.status') }}
                                </th>
                                <th class="px-3 py-2 text-sm rounded-tr bg-slate-100"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="model in collection"
                                :key="model.uuid"
                                :class="`${$spa.css.table.tr} cursor-pointer`"
                                @click.prevent="model.open()"
                            >
                                <td class="px-3 py-2 text-sm border-b border-gray-200" :class="{'text-gray-600': model.status !== 'completed'}">
                                    {{ model.id }}
                                </td>
                                <td class="px-3 py-2 text-sm border-b border-gray-200" :class="{'text-gray-600': model.status !== 'completed'}">
                                    {{ model.created_at.format('D MMMM YYYY') }}
                                </td>
                                <td class="px-3 py-2 text-sm border-b border-gray-200" :class="{'text-gray-600': model.status !== 'completed'}">
                                    {{ $t('export.values.period', {
                                        from: model.from.format('D/MM/YYYY'),
                                        to: model.to.format('D/MM/YYYY')
                                    }) }}
                                </td>
                                <td class="px-3 py-2 text-sm border-b border-gray-200" :class="{'text-gray-600': model.status !== 'completed'}">
                                    {{ model.getClientName() }}
                                </td>
                                <td class="px-3 py-2 text-sm border-b border-gray-200" :class="{'text-gray-600': model.status !== 'completed'}">
                                    {{ $t(`export.values.export_type.${model.export_type}`) }}
                                </td>
                                <td class="px-3 py-2 text-sm border-b border-gray-200" :class="{'text-gray-600': model.status !== 'completed'}">
                                    {{ $t(`export.values.format_type.${model.format_type}`) }}
                                </td>
                                <td class="px-3 py-2 text-sm border-b border-gray-200" :class="{'text-gray-600': model.status !== 'completed'}">
                                    {{ $t(`export.values.status.${model.status}`) }}
                                </td>
                                <td class="px-3 py-2 text-sm border-b border-gray-200" :class="{'text-gray-600': model.status !== 'completed'}">
                                    <v-spa-submenu-container class="h-full" :container="$spa.css.submenu.container.default" position="right-0" :ref="`modelActionsSubmenu-${model.uuid}`">
                                        <template v-slot:default="slotProps">
                                            <div class="min-h-[40px]">
                                                <div class="flex items-center justify-end h-full px-4 py-2 text-gray-600 cursor-pointer hover:text-black" @click.stop.prevent="slotProps.toggle()">
                                                    <i class="text-sm fas fa-ellipsis-h" />
                                                </div>
                                            </div>
                                        </template>
                                        
                                        <template v-slot:submenu>
                                            <div class="relative w-auto" :container="$spa.css.submenu.container.default">
                                                <a href="#" @click.stop.prevent="model.open()" :class="$spa.css.contextmenu.link">
                                                    <i :class="`${$spa.css.contextmenu.icon} far fa-external-link`" />
                                                    {{ $t('actions.open') }}
                                                </a>
                                                <a href="#" @click.stop.prevent="model.download()" :class="$spa.css.contextmenu.link" v-if="model.file">
                                                    <i :class="`${$spa.css.contextmenu.icon} far fa-download`" />
                                                    {{ $t('actions.download') }}
                                                </a>
                                                <a href="#" :class="$spa.css.contextmenu.link">
                                                    <i :class="`${$spa.css.contextmenu.icon} far fa-trash`" />
                                                    {{ $t('actions.delete') }}
                                                </a>
                                            </div>
                                        </template>
                                    </v-spa-submenu-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
    
                    <div class="flex items-center justify-center p-4" v-else>
                        <div class="p-8 text-center cursor-pointer" @click.prevent="$router.push({ name: 'account.exports.create', params: { account: this.$store.state.account.uuid } })">
                            <div class="-my-12">
                                <v-spa-lottie-empty></v-spa-lottie-empty>
                            </div>
                            <p class="text-xl text-gray-400">You didn't request an export yet.</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </v-spa-layout-page>
</template>
​
<script>
    export default {
        data() {
            return {
                collection: null,
                loading: true
            }
        },
        methods: {
            async getExports()
            {
                let data = await window.Accountant.endpoints.export.index();
                this.collection = data.exports;
                console.log(this.collection);
            }
        },
        async created()
        {
            await this.getExports();
            this.loading = false;
        }
    }
</script>