import { generator, getAccountRouteName } from "../utils";
import Pages from "@base/pages";

export default [

    generator.create({
        path: 'exports/create',
        name: getAccountRouteName('exports.create'),
        component: Pages.ExportsCreate,
    }).title("Create Export").sidebar('exports-create').get(),

    generator.create({
        path: 'exports/:export',
        name: getAccountRouteName('exports.show'),
        component: Pages.ExportsShow,
    }).title("Exports Show").sidebar('exports-index').get(),

    generator.create({
        path: 'exports',
        name: getAccountRouteName('exports.index'),
        component: Pages.ExportsIndex,
    }).title("Exports Index").sidebar('exports-index').get(),

    generator.create({
        path: 'clients/index',
        name: getAccountRouteName('clients.index'),
        component: Pages.ClientsIndex,
    }).title("Clients Index").sidebar('clients-index').get(),
];