import './spa';
import Vue from 'vue';
import App from './components/App';
import router from './router';
import i18n from './i18n';
import store from './store';

window.App = new Vue({
    el: '#app',
    template: '<App/>',
    components: { App },
    i18n,
    store,
    router,
    created() {
        window.addEventListener('online', (event) => {
            window.Toasteo.success(
                this.$t('offline_mode.online')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
        window.addEventListener('offline', (event) => {
            window.Toasteo.warning(
                this.$t('offline_mode.offline')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
    }
}).$mount('#app');

import Accountant from './app/Accountant';
window.Accountant = new Accountant();
window.Accountant.init();

// @todo Inspect components!!!