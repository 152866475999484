import BaseEndpoint from '@spa/apps/trustup-pro-base-js/endpoints/BaseEndpoint';
import Export from './../models/Export';
import ExportCollection from './../collections/ExportCollection';

export default class ExportEndpoint extends BaseEndpoint
{

    constructor()
    {
        super(
            'accountant',
            {
                'path': 'exports',
                'responseKey': 'export',
                'responseKeyPlural': 'exports',
                'modelClass': Export,
                'collectionClass': ExportCollection
            }
        );
    }

}