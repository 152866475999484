<template>
    <div class="flex flex-col flex-1 overflow-y-auto">
        <div class="flex-auto py-2">
            <template v-if="$store.state.account">
                <v-spa-layout-sidebar-link
                    section="exports-create"
                    :to="{ name: 'account.exports.create', params: { account: $store.state.account.uuid } }"
                    icon="far fa-plus-square"
                >
                    {{ $t('layout.sidebar.create_exports') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link
                    section="exports-index"
                    :to="{ name: 'account.exports.index', params: { account: $store.state.account.uuid } }"
                    icon="far fa-history"
                >
                    {{ $t('layout.sidebar.exports') }}
                </v-spa-layout-sidebar-link>
                <v-spa-layout-sidebar-link
                    section="clients-index"
                    :to="{ name: 'account.clients.index', params: { account: $store.state.account.uuid } }"
                    icon="far fa-users"
                >
                    {{ $t('layout.sidebar.clients') }}
                </v-spa-layout-sidebar-link>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        //
    }
</script>
