import Export from '../models/Export';
import BaseCollection from '@spa/apps/trustup-pro-base-js/collections/BaseCollection';

export default class ExportCollection extends BaseCollection
{

    constructor(items)
    {
        super(items, {
            modelClass: Export
        });
    }

}