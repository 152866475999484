import Accounts from "./Accounts.vue";
import ExportsCreate from "./Exports/Create.vue";
import ExportsIndex from "./Exports/Index.vue";
import ExportsShow from "./Exports/Show.vue";
import ClientsIndex from "./Clients/Index.vue";

const Pages = {
    Accounts,
    ExportsCreate,
    ExportsIndex,
    ExportsShow,
    ClientsIndex
};

export default Pages;