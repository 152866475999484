const actions = {

    "USER/SET"({ commit }, user) {
        console.log(user);
        commit("USER/SET", user);

        window.TrustUpBase.setup();
        window.Planning.setup();
        window.Worksite.setup();
        window.Accountant.setup();
    },

};

export default actions;