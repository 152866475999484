import BaseApp from '@spa/apps/trustup-pro-base-js/BaseApp';
import ExportEndpoint from './endpoints/ExportEndpoint';

export default class Accountant extends BaseApp
{

    setup()
    {
        this.endpoints = {
            export: new ExportEndpoint()
        };
    }

}