import BaseComponent from "@base/components/_Partials/Base.vue";
import pages from "@base/pages";
import { generator, getAccountRouteName } from "./utils";
import account_routes from "@base/router/routes/account";

const routes = [

    generator.create()
        .homepage()
        .get(),

    generator.create({ component: pages.accounts })
        .accounts()
        .title("Account selection")
        .get(),

    generator.create({
        component: BaseComponent,
        redirect: { name: getAccountRouteName('exports.create') },
        children: account_routes
    }).account().get()

];

export default routes;