<template>
    <v-spa-layout-page>
        <template v-slot:title>
            {{ $t('pages.clients.index.title') }}
        </template>
        <template v-slot:content>
            <div class="flex flex-col space-y-4">
                <v-spa-panel class="flex items-center w-full space-x-4" v-for="client in clients" :key="client.id">
                    <div class="h-[50px] w-[50px]">
                        <img class="object-cover w-full h-full bg-gray-100 rounded-full" :src="client.logo" :alt="client.company">
                    </div>
                    <div>
                        <span class="block">{{ client.company }}</span>
                        <span v-if="client.vat_number" class="block text-sm text-gray-600">{{ client.vat_number }}</span>
                    </div>
                </v-spa-panel>
            </div>
        </template>
    </v-spa-layout-page>
</template>
​
<script>
    import { mapState } from 'vuex';
    export default {
        computed: {
            ...mapState(['clients'])
        }
    }
</script>