<template>
    <v-spa-layout-page>
        <template v-slot:title>
            {{ $t('pages.exports.show.title') }}
        </template>
        <template v-slot:title-button>
            <v-spa-loading-button @clicked="model.download()" v-if="model.file">
                <i class="mr-4 fal fa-download"></i>
                {{ $t('actions.download') }}
            </v-spa-loading-button>
        </template>
        <template v-slot:content>
            <div>
                <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
                <div v-else>
                    <div class="mb-8 columns-3">
                        <div class="mb-4 break-inside-avoid">
                            <p class="text-sm text-gray-600">
                                {{ $t('export.labels.id') }}
                            </p>
                            <p class="text-gray-900">
                                {{ model.id }}
                            </p>
                        </div>
                        <div class="mb-4 break-inside-avoid">
                            <p class="text-sm text-gray-600">
                                {{ $t('export.labels.date') }}
                            </p>
                            <p class="text-gray-900">
                                {{ model.created_at.format('D MMMM YYYY') }}
                            </p>
                        </div>
                        <div class="mb-4 break-inside-avoid">
                            <p class="text-sm text-gray-600">
                                {{ $t('export.labels.client') }}
                            </p>
                            <p class="text-gray-900">
                                {{ model.getClientName() }}
                            </p>
                        </div>
                        <div class="mb-4 break-inside-avoid">
                            <p class="text-sm text-gray-600">
                                {{ $t('export.labels.status') }}
                            </p>
                            <p class="text-gray-900">
                                {{ $t(`export.values.status.${model.status}`) }}
                            </p>
                        </div>
                        <div class="mb-4 break-inside-avoid">
                            <p class="text-sm text-gray-600">
                                {{ $t('export.labels.period') }}
                            </p>
                            <p class="text-gray-900">
                                {{ $t('export.values.period', {
                                    from: model.from.format('D/MM/YYYY'),
                                    to: model.to.format('D/MM/YYYY')
                                }) }}
                            </p>
                        </div>
                        <div class="mb-4 break-inside-avoid">
                            <p class="text-sm text-gray-600">
                                {{ $t('export.labels.documents_count') }}
                            </p>
                            <p class="text-gray-900">
                                {{ model.documents.length }}
                            </p>
                        </div>
                        <div class="mb-4 break-inside-avoid">
                            <p class="text-sm text-gray-600">
                                {{ $t('export.labels.type') }}
                            </p>
                            <p class="text-gray-900">
                                {{ $t(`export.values.export_type.${model.export_type}`) }}
                            </p>
                        </div>
                        <div class="mb-4 break-inside-avoid">
                            <p class="text-sm text-gray-600">
                                {{ $t('export.labels.format') }}
                            </p>
                            <p class="text-gray-900">
                                {{ $t(`export.values.format_type.${model.format_type}`) }}
                            </p>
                        </div>
                    </div>

                    <table v-if="model.documents.length > 0" :class="$spa.css.table.table">
                        <thead>
                            <tr>
                                <th :class="$spa.css.table.th_first">
                                    {{ $t('export_document.labels.number') }}
                                </th>
                                <th :class="$spa.css.table.th">
                                    {{ $t('export_document.labels.date') }}
                                </th>
                                <th :class="$spa.css.table.th">
                                    {{ $t('export_document.labels.subtotal') }}
                                </th>
                                <th :class="$spa.css.table.th">
                                    {{ $t('export_document.labels.total') }}
                                </th>
                                <th :class="$spa.css.table.th_last"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="document in model.documents"
                                :key="document.number"
                                :class="$spa.css.table.tr"
                            >
                                <td :class="$spa.css.table.td_first">
                                    {{ document.number }}
                                </td>
                                <td :class="$spa.css.table.td">
                                    {{ document.date | moment().format('D/MM/YYYY') }}
                                </td>
                                <td :class="$spa.css.table.td">
                                    {{ document.subtotal | price }}
                                </td>
                                <td :class="$spa.css.table.td">
                                    {{ document.total | price }}
                                </td>
                                <td :class="$spa.css.table.td_last">
                                    <div class="flex justify-end w-full">
                                        <v-spa-loading-button :small="true" :color="$spa.css.button.color.gray" @clicked="downloadDocument(document)">
                                            {{ $t('actions.download' )}}
                                        </v-spa-loading-button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </v-spa-layout-page>
</template>
​
<script>
    export default {
        data() {
            return {
                loading: true,
                model: null
            }
        },
        methods: {
            async getExport(id)
            {
                this.model = await window.Accountant.endpoints.export.retrieve(id);
                this.loading = false;
            },

            downloadDocument(model)
            {
                window.open(model.pdf, '_blank');
            }
        },
        async created()
        {
            console.log(this.$route.params.export);
            await this.getExport(this.$route.params.export);
        }
    }
</script>