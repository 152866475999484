<template>
    <v-spa-layout-page>
        <template v-slot:title>
            {{ $t('pages.exports.create.title') }}
        </template>
        <template v-slot:content>
            <form @submit.prevent="storeExport" class="w-full max-w-[600px] mx-auto pt-12">
                
                <v-spa-element id="create-export-form_clients" :form="form" input-name="clients">
                    <select :class="$spa.css.form.select" v-model="form.clients" multiple>
                        <option :value="client" :key="client.uuid" v-for="client in $store.state.clients">
                            {{ client.company }}
                        </option>
                    </select>
                    <p class="text-sm text-gray-600">
                        {{ $t('pages.exports.create.exported_indivudally') }}
                    </p>
                </v-spa-element>

                <v-spa-input type="email" :form="form" input-name="send_to" id="create-export-form_send_to" />

                <v-spa-element id="create-export-form_format_type" :form="form" input-name="format_type">
                    <select :class="$spa.css.form.select" v-model="form.format_type">
                        <option v-for="formatType in ['zip', 'email']" :value="formatType" :key="formatType">
                            {{ $t(`export.values.format_type.${formatType}`) }}
                        </option>
                    </select>
                </v-spa-element>

                <v-spa-element id="create-export-form_export_type" :form="form" input-name="export_type">
                    <select :class="$spa.css.form.select" v-model="form.export_type">
                        <option v-for="exportType in ['invoice', 'credit-note', 'quote', 'expense']" :value="exportType" :key="exportType">
                            {{ $t(`export.values.export_type.${exportType}`) }}
                        </option>
                    </select>
                </v-spa-element>
                
                <div class="flex items-center justify-between space-x-4 max-w-input justify-items-stretch">
                    <v-spa-date-picker :form="form" input-name="from" id="create-export-form_from" :popover="{visibility: 'click'}" />
                    <v-spa-date-picker :form="form" input-name="to" id="create-export-form_to" :popover="{visibility: 'click'}" />
                </div>

                <v-spa-checkbox input-name="include_previously_exported_documents" :form="form" id="create-export-form_include_previously_exported_documents" />
                <div class="flex items-center px-8 py-4 mb-8 space-x-8 border-l-8 border-orange-600 rounded-lg bg-slate-100" v-if="form.include_previously_exported_documents">
                    <div>
                        <i class="text-4xl text-orange-600 fal fa-info-circle"></i>
                    </div>
                    <div>
                        <span class="block mb-2 text-gray-600">
                            {{ $t('pages.exports.create.include_previously_exported_documents') }}
                        </span>
                    </div>
                </div>

                <v-spa-loading-button :loading="form.isLoading()">
                    {{ $t('pages.exports.create.button') }}
                </v-spa-loading-button>
            </form>
        </template>
    </v-spa-layout-page>
</template>
​
<script>

    import Form from '~spa/classes/Form';

    export default {
        data() {
            return {
                form: new Form({
                    clients: [],
                    send_to: '',
                    from: '',
                    to: '',
                    export_type: 'invoice',
                    format_type: 'zip',
                    include_previously_exported_documents: false
                })
            }
        },

        methods: {
            async storeExport()
            {
                if ( this.form.isLoading() ) {
                    return;
                }

                this.form.load();
                let data = this.form.data();
                data.clients = data.clients.map(client => {
                    return {
                        id: client.id,
                        authorization_key: client.authorization_key,
                        company: client.company
                    };
                });
                
                try {
                    let response = await window.Accountant.endpoints.export.store(data);
                    console.log(response);
                    window.Toasteo.success(
                        this.$t('pages.exports.create.success')
                    );
                    this.$router.push({ name: 'account.exports.index', params: { account: this.$store.state.account.uuid } });
                } catch (e) {
                    console.error(e);
                    this.form.onFail(e);
                }
            }
        },

        created()
        {
            this.form.from = window.moment().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD');
            this.form.to = window.moment().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD');
            this.form.clients = [this.$store.state.clients[0]];
            this.form.send_to = this.$store.state.user.email;
        }
    }
</script>