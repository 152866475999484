import BaseModel from '@spa/apps/trustup-pro-base-js/models/BaseModel';

export default class Export extends BaseModel
{

    init(data)
    {
        super.init(data);

        this.created_at = window.moment(this.created_at);
        this.from = window.moment(this.from);
        this.to = window.moment(this.to);
    }

    static getPrefix()
    {
        return 'accountant:export';
    }

    static getEndpoint()
    {
        return window.Accountant.endpoints.export;
    }

    getRoute()
    {
        return {
            name: 'account.exports.show',
            params: {
                export: this.id,
                account: window.App.$store.state.account.uuid
            }
        };
    }

    getDownloadUrl()
    {
        return this.file;
    }

    download()
    {
        window.open(this.getDownloadUrl(), '_blank');
    }

    getClientName()
    {
        return window.App.$store.state.clients.find(c => c.id == this.client_id)?.company;
    }

}